import React, { PureComponent } from 'react';
import ServiceProvider from './service/ServiceProvider';
import {ServiceRoute, RouteFromHref} from './service/routing.jsx'
import spinner from './../images/spinner.gif'

class Content extends PureComponent {

    constructor(props) {
        super(props);
        this.state = { 
            contents: {},
            isLoaded: false
        };
    }

    mapResults(data) {
        const {title, paragraphs} = data;
        const result = {
            "title": [title].map ((title,key) => {
                return(
                     <div key="t" className="header">{title}</div>
                );
            }),
            "content": paragraphs.map((content, key) => {
                const demailifiedContent=content.replace(
                    '<span class="addy">contact (foo) sion-ict (bar) nl</span>',
                    '<a href="mailto:site@sion-ict.nl">site@sion-ict.nl</a>'
                    );
                return (
                  <React.Fragment key={key}>
                    <br/>
                    <div className="paragraph"> 
                        <div dangerouslySetInnerHTML={{__html : demailifiedContent}} />
                    </div>
                   </React.Fragment>
                );
            })
        }
        return result;
    }

    serviceCallback = function(data) {
        const contentList = this.mapResults(data);
        this.setState({ 
            contents: contentList
        });
        this.setState({ isLoaded:true})
        console.log("state: ", this.state.contents)
    }

 
    render() {
        const {title, content} = this.state.contents;
        const {mapping, url} = this.props;
        const route = RouteFromHref();
        if(this.state.isLoaded === false)
        {
            return(
            <div>
                <ServiceProvider url={ServiceRoute(mapping, url, route)} callbackFn={this.serviceCallback.bind(this)} />
                <br/>
                <p>Connecting to background services...</p>
                <img className="spinner" src={spinner} alt="Loading"/>
            </div>);
        }
        return (
        <div className="stpanel content" id="idcontent">
                {title}
                {content}
            </div>
        );
    }

}

export default Content;